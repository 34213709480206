@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*Navbar*/
nav.navbar {
    padding: 18px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition:  0.32s ease-in-out;
  }
  nav.navbar.scrolled {
    padding: 0px 0;
    background-color: rgba(0,0,0,.8);
  }
  nav.navbar a.navbar-brand {
      width: 20%;
  }
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    color: #fff;
    letter-spacing: 0.8px;
    padding: 0 25px;
    font-size: 18px;
    opacity: 0.75;
  }
  nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
  nav.navbar .navbar-nav a.nav-link.navbar-link.active {
      opacity: 1;
  }
  span.navbar-text {
      display: flex;
      align-items: center;
  }
  .social-icon {
      display: inline-block;
      margin-left: 14px;
  }
  .social-icon a {
      width: 42px;
      height: 42px;
      background: rgba(217, 217, 217, 0.1);
      display: inline-flex;
      border-radius: 50%;
      margin-right: 6px;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border: 1px solid rgba(255, 255, 255, 0.5);
  }
  .social-icon a::before {
      content: "";
      width: 42px;
      height: 42px;
      position: absolute;
      background-color: #ffffff;
      border-radius: 50%;
      transform: scale(0);
      transition: 0.3s ease-in-out;
  }
  .social-icon a:hover::before {
      transform: scale(1);
  }
  .social-icon{
      width: 20%;
      z-index: 1;
      transition: 0.3s ease-in-out;
  }
  .social-icon a:hover img {
      filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
  }
  .navbar-text button {
    font-weight: 700;
    color: #fff;
    border: 1px solid #fff;
    padding: 18px 34px;
    font-size: 18px;
    margin-left: 18px;
      position: relative;
      background-color: transparent;
      transition: 0.3s ease-in-out;
  }
  .navbar-text button span {
    z-index: 1;
  }
  .navbar-text button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }
  .navbar-text button:hover {
    color: #121212;
  }
  .navbar-text button:hover::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
  }
  nav.navbar .navbar-toggler:active,
  nav.navbar .navbar-toggler:focus {
      outline: none;
      box-shadow: none;
  }
  nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    top: -2px;
  }
  nav.navbar .navbar-toggler-icon:focus {
      border-bottom: 2px solid #fff;
  }
  nav.navbar .navbar-toggler-icon:after,
  nav.navbar .navbar-toggler-icon:before {
      width: 24px;
      position: absolute;
      height: 2px;
      background-color: #fff;
      top: 0;
      left: 0;
      content: '';
      z-index: 2;
      transition: all 300ms linear;
  }
  nav.navbar .navbar-toggler-icon:after {
      top: 8px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
      transform: rotate(45deg);
      background-color: #fff;
      height: 2px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
      transform: translateY(8px) rotate(-45deg);
      background-color: #fff;
      height: 2px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
      border-color: transparent;
  }

  /*Cibers*/

  /* Estilos para la página de Controles de Ciberseguridad */
.cibers {
    text-align: center;
    background: #2C3E50;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #4CA1AF, #2C3E50);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #4CA1AF, #2C3E50); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    padding: 20% 10%; /* Ajusta el padding según tus necesidades */
  }
  
  .inicial {
    width: 70%;
  }
  
  .cibers h1 {
    display: inline-block;
  }

  .primerP {
    font-family: 'Kanit';
    font-weight: 300;
    margin-top: 50px;
    margin-bottom: 60px;
  }
  
  .icon {
    display: inline-flex;
    flex-wrap: wrap;
    padding: 10px;
    width: 50%;
  }

  .ciber-icons p{
    font-family: 'Kanit';
    font-size: 17px;
    color: #fff;
    margin: auto;
  }

  .banner-ciber {
    margin-top: 0;
    padding: 120px 0 100px 0;
    background-image: url('./assets/img/banners/1.jpg');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .banner-ciber h1 {
    background-color: #000;
    text-align: center;
    border-radius: 500px;
    opacity: 0.8;
    margin-top: 150px;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 0.8px;
    margin-bottom: 20px;
    display: block;
  }

.ciber-icons img {
  animation: updown 2s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(-5px);
    }
}

/*Seccion  de beneficios*/

.contenedorlista {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: -25%;
}
.lista li {
  text-indent: 2px;
  list-style: none;
  margin-left: 45px;
  margin-bottom: 15px;
}
.xd {
  font-size: 70%;
}
.lista img {
  width: 12%;
  margin-left: -35%;
}
.lista p {
  font-size: 50px;
  padding: 15px 0;
  margin-left: 25px;
}
.listado li {
  list-style: none;
}
.listado img {
  width: 0;
}

@media (min-width: 560px) {
  .contenedorlista {
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
    text-wrap: nowrap;
    margin-bottom: -25%;
  }
  .lista li {
    list-style: none;
    margin-left: 15px;
    margin-bottom: 15px;
  }
  .listado img {
    padding: 55% 0;
    margin-left: 10px;
    width: 100%;
    border-radius: 80px;
  }
}
@media (min-width: 768px) {

  .icon{
    width: 25%;
    display: inline-flex;
    flex-wrap: wrap;
}
.cibers{
    margin-top: -155px;
}
.primerP{
    margin-top: -180px;
    font-size: 20px;
}
  .contenedorlista {
    display: flex;
  }
  .lista {
    margin-bottom: 40px;
  }
  .lista li {
    text-align: start;
    font-family: 'Kanit';
    font-weight: 400;
    margin-left: 20%;
    font-size: 110%;
  }
  .lista img {
    width: 30px;
  }
  .lista p {
    font-size: 50px;
    padding: 15px 0;
    margin-left: -75px;
  }
  .listado img {
    padding: 5% 0;
    margin-left: 60px;
    width: 100%;
  }
}

  /* Estilos para la página de SOC */


  .soc {
    text-align: center;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    padding: 20% 10%; /* Ajusta el padding según tus necesidades */
  }
  
  .soc h1 {
    display: inline-block;
  }

  .primerPS {
    font-family: 'Kanit';
    font-weight: 300;
    margin-top: 50px;
    margin-bottom: 60px;
  }
  
  .icon1 {
    display: inline-flex;
    flex-wrap: wrap;
    padding: 1px;
    width: 50%;
    background-color: #fff;
    border-radius: 5px;
    margin: 10px 20px;
    box-shadow: 5px 10px #000;
  }
  .icon1 h4 {
    margin: auto;
    font-family: 'Kanit';
    color: rgba(74, 47, 189); 
  }
  .soc-icons p{
    font-family: 'Kanit';
    color: gray;
    margin: auto;
  }
  .listado img {
    margin-top: 15%;
  }
  .as {
    margin: auto;
  }

  .banner-soc {
    margin-top: 0;
    padding: 120px 0 100px 0;
    background-image: url('./assets/img/banners/2.jpg');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .banner-soc h1 {
    background-color: #000;
    text-align: center;
    border-radius: 500px;
    opacity: 0.8;
    margin-top: 150px;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 0.8px;
    margin-bottom: 20px;
    display: block;
  }

  @media (min-width: 768px) {
    .icon1{
        width: 28%;
        display: inline-flex;
        padding: 1px;
        flex-wrap: wrap;
        background-color: #fff;
        border-radius: 5px;
        margin: 10px 20px;
        box-shadow: 5px 10px #000;
    }
    .icon1 h4 {
      font-family: 'Kanit';
      margin: auto;
      color: rgba(74, 47, 189); 
    }
    .lista p {
      font-family: 'Kanit';
      margin-top: 25px;
    }
    .soc{
        margin-top: -155px;
    }
    .primerPS{
      font-family: 'Kanit';
        margin-top: -200px;
        font-size: 20px;
    }
    .as {
      margin: auto;
    }
}

 /* Estilos para la página de Asesoria */
.asesoria {
  text-align: center;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  padding: 20% 10%; /* Ajusta el padding según tus necesidades */
}

.iniciales {
  width: 70%;
}

.primerPSS {
  font-family: 'Kanit';
  font-weight: 300;
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 60px;
}

.triada {
  margin-top: auto;
}

.banner-ase {
  margin-top: 0;
  padding: 120px 0 100px 0;
  background-image: url('./assets/img/banners/3.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner-ase h1 {
  background-color: #000;
  text-align: center;
  border-radius: 500px;
  opacity: 0.8;
  margin-top: 150px;
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 0.8px;
  margin-bottom: 20px;
  display: block;
}

@media (min-width: 768px) {
  .asesoria{
    margin-top: -155px;
  }
  .primerPSS{
    font-family: 'Kanit';
    font-weight: 500;
      margin-top: -200px;
      font-size: 22px;
  }
}